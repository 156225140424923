import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"320","min-width":"320","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey white--text"},[_vm._v(_vm._s(_vm.$t("message.privacy")))]),_c(VCardText,{staticClass:"px-4 pt-2 pb-0"},[_vm._v(" "+_vm._s(_vm.$t("message.privacyContent"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","rounded":"","depressed":"","dark":"","small":""},on:{"click":function($event){return _vm.$emit('dismissed')}}},[_vm._v(" "+_vm._s(_vm.$t("message.close"))+" ")]),_c(VSpacer)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }