<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="320"
      min-width="320"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey white--text">{{ $t("message.privacy") }}</v-card-title>

        <v-card-text class="px-4 pt-2 pb-0">
          {{ $t("message.privacyContent") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            rounded
            depressed
            dark
            small
            @click="$emit('dismissed')"
          >
            {{ $t("message.close") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: [ 'dialog' ],
  computed: {
    ...mapGetters({
      windowSize: 'shared/windowSize'
    })
  }
}
</script>
